import React from "react";
import {
    QUEUE_NAME_AHS,
    QUEUE_NAME_BALLAD_CUSTOMER_SERVICE,
    QUEUE_NAME_BH,
    QUEUE_NAME_BSMH_BS_CUSTOMER_SERVICE,
    QUEUE_NAME_BSMH_BS_ORDERS,
    QUEUE_NAME_BSMH_ESCALATIONS,
    QUEUE_NAME_BSMH_MH_CUSTOMER_SERVICE,
    QUEUE_NAME_BSMH_MH_ORDERS,
    QUEUE_NAME_CHRIST_HOSPITAL_CUSTOMER_SERVICE,
    QUEUE_NAME_COVENANT_HEALTH_CUSTOMER_SERVICE,
    QUEUE_NAME_FARFIELD_CUSTOMER_SERVICE,
    QUEUE_NAME_NCH_CUSTOMER_SERVICE,
    QUEUE_NAME_NICLAUS_CUSTOMER_SERVICE,
    QUEUE_NAME_ROPER_CUSTOMER_SERVICE,
    QUEUE_NAME_SCRIPPS_1_800,
    QUEUE_NAME_SCRIPPS_ESTIMATES,
    QUEUE_NAME_MAYO
} from "../../utils/constant";

export const getAgentHelloMessage = (contact, agentName) =>
{
    let queueName;
    try {
        queueName = contact.connection.getQueue().name;
    } catch (e) {}

    switch (queueName) {
        case QUEUE_NAME_BSMH_BS_CUSTOMER_SERVICE:
        case QUEUE_NAME_BSMH_MH_CUSTOMER_SERVICE:
        case QUEUE_NAME_BSMH_ESCALATIONS:
        case QUEUE_NAME_BSMH_BS_ORDERS:
        case QUEUE_NAME_BSMH_MH_ORDERS:
            return  "My name is " + agentName + ", I may need to ask you a few questions " +
                "in order for me to better assist you!";
        case QUEUE_NAME_SCRIPPS_1_800:
            return 'Hello, my name is ' + agentName + '. I am a Health Resource Coordinator at Scripps Health. ' +
                'How can I help you today?';
        case QUEUE_NAME_SCRIPPS_ESTIMATES:
            return 'Hi, my name is ' + agentName + '. I am a Patient Service Specialist ' +
                'on the Estimate team at Scripps Health. How can I help you?';
        case QUEUE_NAME_NCH_CUSTOMER_SERVICE:
        case QUEUE_NAME_FARFIELD_CUSTOMER_SERVICE:
        case QUEUE_NAME_NICLAUS_CUSTOMER_SERVICE:
        case QUEUE_NAME_BALLAD_CUSTOMER_SERVICE:
        case QUEUE_NAME_ROPER_CUSTOMER_SERVICE:
        case QUEUE_NAME_AHS:
        case QUEUE_NAME_BH:
        case QUEUE_NAME_COVENANT_HEALTH_CUSTOMER_SERVICE:
        case QUEUE_NAME_CHRIST_HOSPITAL_CUSTOMER_SERVICE:
            return "My name is " + agentName + ", I may need to ask you a few questions in order for me to better assist you!"
        case QUEUE_NAME_MAYO:
            return "You’re now connected with " + agentName
    }
    return false;
}
