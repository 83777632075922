import React, {useEffect, useMemo, useState} from "react";
import {ThreeDots as LoaderThreeDots} from "react-loader-spinner";

export const CustomerAttributesMessage = (props) => {
    const {getContactDataAttributes, messageTime} = props;

    const [contactDataAttributes, setContactDataAttributes] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getContactDataAttributes()
            .then(data => setContactDataAttributes((data || {})?.attributes))
            .finally(() => setIsLoading(false));
    }, []);

    return <>
        {
            <div className={"row message-left"}>
                <div className="col-md-9">
                    <p>
                        Customer info:<br/>
                        {
                            isLoading ?
                                <LoaderThreeDots
                                    color="#d7d7d7"
                                    height={30}
                                    width={30}
                                    timeout={0}
                                />
                                :
                                <>
                                    Patient Name: {contactDataAttributes?.patientName || '-'}<br/>
                                    Medical Record Number: {contactDataAttributes?.mrn || '-'}
                                </>
                        }
                    </p>
                    <span className={"message-time "}>
                        {messageTime}
                    </span>
                </div>
                <div className="col-md-3">
                </div>
            </div>
        }
    </>;
}
