import Cookies from "universal-cookie";
import axios from "axios";

const cookieName = 'Odeza_agentChatConfigName';

export const AgentChatConfigStoragePath = {
    'default': {
        title: 'Mayo',
        path: 'Mayo/00c7ded1-a590-4d73-b7c7-084b5dfef8dc'
    },
    'ec13db5b-1946-4200-87c0-27cf39cd1092': {
        title: 'Mayo',
        path: 'Mayo/00c7ded1-a590-4d73-b7c7-084b5dfef8dc'
    },
    'bf7d2463-48e4-479d-a358-45ec4a4d79af': {
        title: 'Mayo',
        path: 'Mayo/00c7ded1-a590-4d73-b7c7-084b5dfef8dc'
    },
    '5f8077f2-28a9-4d99-9b84-0880f8afb42c': {
        title: 'Mayo',
        path: 'Mayo/00c7ded1-a590-4d73-b7c7-084b5dfef8dc'
    },
    '55f14620-a0fd-4e32-a571-260680cdcd0e': {
        title: 'Mayo',
        path: 'Mayo/00c7ded1-a590-4d73-b7c7-084b5dfef8dc'
    },
    'da9abc95-f7d8-48d8-a6f5-f730c8ec3982': {
        title: 'Mayo',
        path: 'Mayo/00c7ded1-a590-4d73-b7c7-084b5dfef8dc'
    },
    '79de92f8-e07b-4c0e-bf37-ba467529b1a2': {
        title: 'CHOA',
        path: 'CHOA/25546353-bfd8-4cd4-91da-e53bafc1bb4f'
    },
    'e9f4856e-01e0-449e-8fe1-e106eba9db97': {
        title: 'MBAC',
        path: 'MBAC/2fc7c1cb-bd09-466d-b2d4-8fcc6f7013b5'
    },
    '5d423947-8ae0-4f6c-8e0c-e8a7b394e0e9': {
        title: 'MCT',
        path: 'MCT/386447d9-d0d6-44d3-a89c-a5e43120cf94'
    },
    '8': {
        title: 'Atrium',
        path: 'Atrium/8a92db7d-a69e-4add-98f1-42911d832e37'
    },
    '14': {
        title: 'Bon Secours & Mercy Health',
        path: 'BSMH/bc92e5d8-4627-47fb-9724-9f56cefe7e4e'
    },
    '18': {
        title: 'Scripps',
        path: 'Scripps/154efe8e-8156-4dcd-b3b5-09a9e112aea2'
    },
    '13': {
        title: 'NCH',
        path: 'NCH/3e785cc9-c17d-4b66-a3cc-16b6d32746df'
    },
    '19': {
        title: 'Nicklaus',
        path: 'Nicklaus/9c68d18e-2395-4a15-a235-4873388cd58b'
    },
    '20': {
        title: 'Fairfield',
        path: 'Fairfield/b3a15743-2035-430b-9172-dde61136bbb4'
    },
    '21': {
        title: 'AHS',
        path: 'AHS/db4dfc62-8957-47e9-91e0-c2825b9639f4'
      },
    '22': {
        title: 'BH',
        path: 'BH/a62bfd50-1e90-4123-a89c-499b5f60366f'
    },
    '10': {
        title: 'Ballad Health',
        path: 'BaH_RSFH/938bd856-7171-4411-ba4c-254a237b0eb6'
    },
    '16': {
        title: 'Roper St. Francis Healthcare',
        path: 'BaH_RSFH/938bd856-7171-4411-ba4c-254a237b0eb6'
    },
    '12': {
        title: 'CH',
        path: 'TCHHN_CH/eda5161e-59b5-4a47-a7eb-cb43a06e912b'
    },
    '24': {
        title: 'TCHHN',
        path: 'TCHHN_CH/eda5161e-59b5-4a47-a7eb-cb43a06e912b'
    },
    '80000000': {
        title: '---',
    },
    '80000001': {
        title: 'Multi-tenant',
        path: 'Multi-tenant/d27bc8a1-99f2-469c-8de6-13699bddd30c'
    }
}

export const getAgentChatConfigFromStorage = (clientId) => {
    const path = AgentChatConfigStoragePath[clientId]?.path;
    return new Promise((resolve, reject) => {
        if (!path) {
            return reject('Unknown Organization ID');
        }
        axios.get(process.env.REACT_APP_AMAZON_CONFIG_STORAGE_URL + path + '?' + Math.random(), {
            headers: {
                'x-api-key': process.env.REACT_APP_AMAZON_API_KEY,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                try {
                    let data = response.data;
                    if (typeof data !== 'object') {
                        data = JSON.parse(data);
                    }
                    resolve(data);
                } catch (e) {
                    reject('Wrong AgentChat configuration format. Error:' + e.message);
                }
            })
            .catch(() => reject('Can\'t get configuration for AgentChat'));
    });
};

export const getAgentChatConfigName = () => {
    const cookies = new Cookies();
    return cookies.get(cookieName);
}

export const setAgentChatConfigName = (configName) => {
    const cookies = new Cookies();
    cookies.set(cookieName, configName, {path: '/', sameSite: 'strict'});
}

export const generateLoginUrl = (redirectUrl, instanceId) => {
    return 'https://odeza.auth0.com/authorize?client_id=' + process.env.REACT_APP_AUTH0_CLIENT_ID +
        '&response_type=code' +
        '&connection=Username-Password-Authentication' +
        '&scope=openid%20profile%20email' +
        '&redirect_uri=' + redirectUrl + '/agent/auth%3Fprovider=auth0%26old=1%26instance_id=' + instanceId;
}
