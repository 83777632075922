import React, {useState} from "react";
import {getAgentHelloMessage} from "./AgentHelloMessage";
import {normalizeDateTime, transformToMessageTime, transformURLToLinkInText} from '../../utils/transformers'
import {PrescriptionMessage} from "./PrescriptionMessage";
import {ImmunizationMessage} from "./ImmunizationMessage";
import {NurseAdviceMessage} from "./NurseAdviceMessage";
import {ReferralInfoMessage} from "./ReferralInfoMessage";
import {CustomerInfoMessage} from "./CustomerInfoMessage";
import {AgentTransferNoteMessage} from "./AgentTransferNoteMessage";
import {CustomerAttributesMessage} from "./CustomerAttributesMessage";
import { i18n } from "../../utils/helpers";
import {GLPMessage} from "./GLPMessage";

const Message = (props) => {
    const {contact, message, showTime} = props;

    let content = message.Content;
    let referralInfo = message.referralInfo;
    let prescription = message.prescriptionRefillRequest;
    let immunization = message.immunizationRequest;
    let nurseAdvice = message.nurseAdviceRequest;
    let glp = message.glpRequest;
    let agentTransferNote = message.agentTransferNote;

    let role = 'system';
    switch (message.ParticipantRole) {
        case 'AGENT':
            role =  'sender';
        break;
        case 'CUSTOMER':
            role = 'receiver';
        break;
        default:
            role = 'system';
        break;
    }

    let messageJson;
    if (message.DisplayName === 'BOT' || message.DisplayName === 'SYSTEM_MESSAGE') {
        try {
            messageJson = JSON.parse(message.Content);
            content = messageJson.title;
        }
        catch (e) {}
    }

    if (content === 'START_CHAT') {
        content = 'Start Chat';
    }
    else if (message.Type === 'EVENT' && message.ContentType === 'application/vnd.amazonaws.connect.event.chat.ended') {
        content = 'Chat ended';
    }
    else if (message.Type === 'EVENT' && message.ParticipantRole === 'AGENT' && message.ContentType === 'application/vnd.amazonaws.connect.event.participant.joined') {
        content = getAgentHelloMessage(contact, message.DisplayName);
        //i18n.setLocale(contact && contact.attributes.language?contact.attributes.language.value:"en")
        if (message.usedQueueName === 'Billing ES') {
            i18n.setLocale('es')
        } else {
            i18n.setLocale('en')
        }
        const displayName = message.DisplayName

        if (!content) {
            content = 'Hi, my name is ' + displayName + ', how can I help you?';
        }

        if (prescription?.additionalData?.prescription) {
            content = 'Hi, my name is ' + message.DisplayName + '. Please allow me 2 minutes to review this request.';
            if (Array.isArray(prescription.additionalData.prescription)
                && prescription.additionalData.prescription.length
            ) {
                content = content + ' I will confirm when it is complete. If you disconnect before the request is confirmed it will be cancelled.';
            }
        }

        if (immunization?.additionalData?.immunization) {
            content = 'Hi, my name is ' + message.DisplayName + '. Please allow me 2 minutes to review this request. ' +
                'I will confirm when it is complete. If you disconnect before the request is confirmed it will be cancelled.';
        }

        if (props.showTransferMessage) {
            content = i18n.__('Hi, my name is %s! Give me one moment to review your request.', message.DisplayName)

            if (message.usedQueueName === 'Billing' || message.usedQueueName === 'Billing ES') {
                content = i18n.__('Hi, my name is %s, can I please have the patient’s name and Date of Birth or Responsible Person Number?', message.DisplayName);
            }
        }
    }

    if (!content) {
        return '';
    }

    content = (Array.isArray(content)) ? content.join('<br/><br/>').toString() : content.toString();
    if (content.includes('[!]')) {
        content = content.replace('[!]', '<br/><br/>');
    }

    // check Back action
    if (content.indexOf('sys_back:') === 0) {
        const sysBack = content.split(':');
        content = '(Go Back) ' + sysBack[2];
    }

    content = transformURLToLinkInText(content); // wrap URLs with <a/> tag

    const messageTime = transformToMessageTime(normalizeDateTime(message.AbsoluteTime));

    return (<>
            {
                role === 'receiver' ?
                    <div className={"row message-left"}>
                        <div className="col-md-9">
                            <p dangerouslySetInnerHTML={{__html: content}} />
                            <span className={"message-time " + (showTime ? '' : 'd-none')}>
                                {messageTime}
                            </span>
                        </div>
                        <div className="col-md-3">
                        </div>
                    </div>
                    :
                    <div className={"row message-right"}>
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-9 text-right">
                            <p dangerouslySetInnerHTML={{__html: content}} />
                            <span className={"message-time " + (showTime ? '' : 'd-none')}>
                                {messageTime}
                            </span>
                        </div>
                    </div>
            }
            {
                message.customerInfo
                    && !referralInfo
                    && !prescription
                    && !immunization
                    && !nurseAdvice ? <CustomerInfoMessage data={message.customerInfo} messageTime={messageTime} /> : ''
            }
            {
                referralInfo ? <ReferralInfoMessage data={referralInfo} messageTime={messageTime}/> : ''
            }
            {
                message.getContactDataAttributes ?
                    <CustomerAttributesMessage getContactDataAttributes={message.getContactDataAttributes} messageTime={messageTime} /> : ''
            }
            {
                prescription ? <PrescriptionMessage data={prescription} messageTime={messageTime} /> : ''
            }
            {
                immunization ? <ImmunizationMessage data={immunization} messageTime={messageTime} /> : ''
            }
            {
                nurseAdvice ? <NurseAdviceMessage data={nurseAdvice} messageTime={messageTime} /> : ''
            }
            {
                glp ? <GLPMessage data={glp} messageTime={messageTime} /> : ''
            }
            {
                agentTransferNote ? <AgentTransferNoteMessage note={agentTransferNote} messageTime={messageTime} /> : ''
            }
        </>
    );
};

export default Message;
