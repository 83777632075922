import {toast} from "react-toastify";
import Rodal from "rodal";
import React, {useState} from "react";
import ChatTransferNotify from "./ChatTransferNotify";
import PopupSpinner from "../PopupSpinner";
import ChatTransferModalOld from "./ChatTransferModalOld";
import styles from "./ChatTransferModal.module.css";
import axios from "axios";
import {checkEmptyInputText} from "../../../utils/helpers";
import api from "../../../services/api"
import {Buffer} from 'buffer';
import Button from 'react-bootstrap/Button'

const TRANSFER_STATE_SPINNER = 0;
const TRANSFER_STATE_CONTENT = 1;
const TRANSFER_STATE_EMPTY_CONTENT = 2;
const TRANSFER_STATE_ERROR = 3;

const ChatTransferModal = (props) => {

    const {
        newVersion,
        agentChatConfiguration,
        agentConfiguration,
        showPopup, setShowPopup,
        transferContentState,
        currentCustomerName,
        endpoints,
        changeQueue,
        showTransfer,
        contacts,
        currentContact,
        queueName,
        config
    } = props;

    const [queueKey, setQueueKey] = useState(false);
    const [noteText, setNoteText] = useState('');

    const {instanceId, chatTransfer} = agentChatConfiguration;
    const enableAgentNote = (chatTransfer?.enableAgentNote === true);

    if (!newVersion) {
        return <ChatTransferModalOld {...props} />;
    }

    const sendAgentTransferNote = async () => {
        if (checkEmptyInputText(noteText)) {
            return;
        }
        try {
            const noteNum = Object.keys(props.getAgentsTransferNote(contacts[currentContact])).length + 1;
            const note = JSON.stringify({
                agentName: agentConfiguration.name,
                agentUsername: agentConfiguration.username,
                value: noteText,
            });
            const noteEncoded = Buffer.from(note).toString('base64');
            let urlData = encodeURI(`instanceId=${instanceId}&contactId=${currentContact}&n=${noteNum}&note=${noteEncoded}`);
            await axios.patch(process.env.REACT_APP_AMAZON_API_URL + '/agent/transfer/note?' + urlData, urlData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            let data  = {
                contactId:currentContact,
                queue:queueName,
                transferringMessage: noteText,
                agentUsername: agentConfiguration.name,
                transferringQueue:endpoints[queueKey].name??"",
            }
            api.sendTransferNote(config,data)
            setNoteText('');
        } catch (e) {
            console.log(e);
        }
    }

    const onCancel = () => {
        setQueueKey(false);
        setNoteText('');
        setShowPopup(false);
    }

    return <Rodal visible={showPopup}
                  onClose={onCancel}
                  animation='slideDown'
                  width={520}
                  customStyles={{marginTop: '80px'}}
                  className={'chatTransfer'}
    >
        <div className={styles.chatTransferHeader} id="chat-transfer-header">Transfer Chat</div>
        <div className="modal-data">
            {
                transferContentState === TRANSFER_STATE_SPINNER ? <PopupSpinner style={{margin: '100px 0'}} /> : ''
            }
            {
                transferContentState === TRANSFER_STATE_CONTENT ?
                    <>
                        <p className={styles.transferConversationTitle} id="transfer-conversation-title">Transfer conversation with <b><u>{currentCustomerName}</u></b> to:</p>
                        <p className={styles.transferConversationQueueTitle}>Queue</p>
                        <div className="container">
                            <div className={"row row-cols-2 " + styles.queueNameTableRow}>
                                {
                                    endpoints && endpoints.length > 0 ?
                                        endpoints.map((item, key) => {
                                            return item.showEndpoint === true ?
                                                <div key={key} className="col" onClick={() => setQueueKey(key)}>
                                                    <label className={styles.queueName}>
                                                        <input type='radio'
                                                               checked={queueKey === key}
                                                               className={styles.radioButton}
                                                               readOnly
                                                        />
                                                        {item.name}
                                                    </label>
                                                </div> : ''
                                        }) : ''
                                }
                            </div>
                        </div>

                        {
                            enableAgentNote ? <>
                                    <p style={{marginTop: '32px'}}>
                                        <span className={styles.noteHeader}>Leave a note to next agent</span>
                                        <span className={styles.noteHeaderOptional}>(optional)</span>
                                    </p>
                                    <textarea
                                        id="contact-transfer-note"
                                        className={styles.noteText}
                                        placeholder={"Leave message here..."}
                                        maxLength={500}
                                        onChange={(e) => setNoteText(e?.target?.value || '')}
                                    />
                                    <div className={styles.noteCharCounter}>{noteText.length || 0}/500</div>
                                </>
                                :
                                <p style={{marginTop: '32px'}} />
                        }

                        <hr className={styles.chatTransferBottomLine}/>
                        <div style={{textAlign: 'right', padding: '24px 9px 9px 9px'}}>
                            <Button
                                className={styles.cancelButton + " category-cancel"}
                                variant="outline-primary"
                                onClick={onCancel}
                                id="contact-transfer-cancel-button"
                            >
                                Cancel
                            </Button>

                            <Button className={styles.transferButton}
                                    id="contact-transfer-button"
                                    disabled={queueKey === false}
                                    onClick={() => {
                                        sendAgentTransferNote();
                                        changeQueue(queueKey);
                                        setShowPopup(false);
                                        toast(<ChatTransferNotify/>);
                                    }}
                            >
                                Transfer
                            </Button>
                        </div>
                    </>
                    :
                    ''
            }
            {
                transferContentState === TRANSFER_STATE_EMPTY_CONTENT ?
                    <>
                        <p className={styles.transferConversationTitle} id="transfer-conversation-title">No agents available.</p>
                        <div style={{textAlign: 'right', paddingTop: '25px'}}>
                            <Button className={styles.transferButton}
                                    id="contact-transfer-ok-button"
                                    onClick={() => {
                                        setShowPopup(false)
                                    }}
                            >
                                Ok
                            </Button>
                        </div>
                    </>
                    :
                    ''
            }
            {
                transferContentState === TRANSFER_STATE_ERROR ?
                    <>
                        <p className={styles.transferConversationTitle} id="transfer-conversation-title">Can't get available agents.</p>
                        <div style={{textAlign: 'right', paddingTop: '25px'}}>
                            <Button
                                className={styles.cancelButton + ' category-cancel'}
                                variant="outline-primary"
                                onClick={onCancel}
                                id="contact-transfer-cancel-button"
                            >
                                Cancel
                            </Button>

                            <Button
                                className={styles.transferButton}
                                onClick={() => {
                                    showTransfer()
                                }}
                                id="contact-transfer-retry-button"
                            >
                                Retry
                            </Button>

                        </div>
                    </>
                    :
                    ''
            }
        </div>
    </Rodal>
}

export default ChatTransferModal;
